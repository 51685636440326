import '../../styles/Footer.css'
import React from 'react'

export default function Footer() {
  return (
    <div className="Footer">
      <div className="FooterGroup">
        <div className="FooterContent">
          <div className="FooterTitle">Copyright © 2025 Henry Labs Inc.</div>
          <div className="FooterButtons">
            <a href="mailto:%20support@clerkie.io" className="FooterButton">
              Support
            </a>
            <a href="mailto:%20press@clerkie.io" className="FooterButton">
              Press
            </a>
            <a
              href="https://s3-us-west-2.amazonaws.com/clerkie-legal/Henry+Labs+-+Privacy+Policy.pdf"
              className="FooterButton"
            >
              Privacy
            </a>
            <a
              href="https://s3-us-west-2.amazonaws.com/clerkie-legal/Henry+Labs+-+Terms+of+Use.pdf"
              className="FooterButton"
            >
              Terms of Service
            </a>
            <a href="https://app.clerkie.io/donotsell" className="FooterButton">
              Do Not Sell My Information
            </a>
            <a
              href="https://clerkie-legal.s3-us-west-2.amazonaws.com/20200619+Clerkie+Advisory+LLC+Form+CRS.pdf"
              className="FooterButton"
            >
              Form CRS
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
